
import './sass/main.scss';

import $ from 'jquery'
import img from './images/LisperLogoBlack.svg'

$(() => {
    console.log('wtf')
    var nav = $("nav")
    var menu = $("#menu")
    console.log(menu);
     menu.on("click", () => nav.hasClass("nav-show")
         ? nav.removeClass("nav-show")
         : nav.addClass("nav-show"))
})
        
    
